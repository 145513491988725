<template>
  <div class="addTicket">
    <myForm
      ref="formRef"
      :fromItem="fromItem"
      @submit="submitApi"
      @handle-upload-img="handleUploadImg"
    />
  </div>
</template>

<script>
import {
  addShopTicketApi,
  getShopTicketBuyIdApi,
  editShopTicketApi
} from "@/utils/api";
import { toRefs, reactive, watch, ref } from "vue";
import { confirmDialog, isJson, showMessage, sleep } from "@/utils/util";
import { useRoute } from "vue-router";
import router from "@/router";
import myForm from "@/components/myForm.vue";
export default {
  components: { myForm },
  setup() {
    const route = useRoute();
    const ticketId = ref(null);

    const state = reactive({
      formRef: "",
      fromItem: [
        { type: "input", label: "票种名称", prop: "name", value: "" },
        {
          type: "input",
          label: "运费",
          prop: "freight",
          value: 0,
          tip: "0代表包邮"
        },
        {
          type: "radio",
          label: "销售类型",
          prop: "buyType",
          value: "",
          radioList: [
            { label: "按本", value: "0" },
            { label: "按张", value: "1" }
          ],
          hidden: true
        },
        {
          type: "input",
          label: "零售数量",
          prop: "ticketNumber",
          value: "",
          tip: "多少张票（换算），例如，1本60张的体彩整本，如果零售是整本卖，该属性值为1，如果零售是单张卖，该属性就是60"
        },
        {
          type: "input",
          label: "零售价格（元）",
          prop: "ticketPrice",
          value: ""
        },
        {
          type: "input",
          label: "进票单位",
          prop: "unit",
          value: "",
          tip: "代理商进票系统中显示的单位。"
        },
        {
          type: "input",
          label: "零售单位",
          prop: "retailUnit",
          value: "",
          tip: "零售时显示的单位。"
        },
        {
          type: "input",
          label: "描述",
          prop: "description",
          value: "",
          rules: false
        },
        {
          type: "input",
          label: "标签",
          prop: "tags",
          value: "",
          rules: false,
          tip: "多个标签请使用中文逗号分隔。"
        },
        {
          type: "input",
          label: "tips",
          prop: "tips",
          value: "",
          rules: false,
          tip: "销售时商品名称下方显示的文字。"
        },
        {
          type: "input",
          label: "备注",
          prop: "remark",
          value: "",
          rules: false
        },
        {
          type: "uploadImg",
          prop: "mainImg",
          label: "上传图片",
          value: "",
          urlList: []
        },
        {
          prop: "gameType",
          type: "radio",
          label: "彩种",
          value: "",
          radioList: [
            { label: "福彩", value: "0" },
            { label: "体彩", value: "1" }
          ]
        }
      ]
    });

    function getPropIndex(propStr) {
      return state.fromItem.findIndex(item => item.prop === propStr);
    }

    // watch(
    //   () => state.fromItem[2].value,
    //   res => {
    //     if (res == 0) {
    //       state.fromItem[getPropIndex("retailUnit")].value = "本";
    //       state.fromItem[getPropIndex("unit")].value = "本";
    //       state.fromItem[getPropIndex("ticketNumber")].value = "1";
    //       state.fromItem[getPropIndex("ticketNumber")].hidden = true;
    //     } else {
    //       state.fromItem[getPropIndex("retailUnit")].value = "张";
    //       state.fromItem[getPropIndex("unit")].value = "张";
    //       state.fromItem[getPropIndex("ticketNumber")].hidden = false;
    //     }
    //   },
    //   { immediate: true }
    // );

    async function loadData() {
      const { id } = route.query;
      if (id) {
        ticketId.value = id;
        const res = await getShopTicketBuyIdApi(id);
        if (res.code == 0) {
          console.log(res);
          state.fromItem[getPropIndex("buyType")].value =
            res.data.ticketNumber > 1 ? "1" : "0";
          state.fromItem[getPropIndex("mainImg")].urlList = [res.data.mainImg];
          state.fromItem.forEach(item => {
            Object.keys(res.data).forEach(key1 => {
              if (key1 == item.prop) {
                item.value = res.data[key1] + "";
              }
            });
          });
          if (isJson(res.data.detail)) {
            const { tags, tips } = JSON.parse(res.data.detail);
            if (tags) {
              state.fromItem[getPropIndex("tags")].value = tags.join(",");
            }
            if (tips) {
              state.fromItem[getPropIndex("tips")].value = tips;
            }
          }
        }
      }
    }
    loadData();

    async function submitApi(e) {
      console.log(e);
      const api = ticketId.value ? editShopTicketApi : addShopTicketApi;
      const tags = e.tags.split(",");
      const req = {
        ...e,
        detail: JSON.stringify({ tags, tips: e.tips }),
        rotationImg: e.mainImg,
        price:
          state.fromItem[getPropIndex("ticketNumber")].value * e.ticketPrice,
        ticketNumber: state.fromItem[getPropIndex("ticketNumber")].value,
        productType: 1
      };
      if (ticketId.value) {
        req.id = ticketId.value;
      }
      const res = await api(req);
      if (res.code == 0) {
        if (ticketId.value) {
          showMessage("操作成功");
          await sleep(1000);
          router.go(-1);
        } else {
          confirmDialog(
            "操作成功",
            "继续新增/查看列表",
            "继续新增",
            "查看列表"
          ).then(res => {
            console.log(state.formRef);
            res ? state.formRef.onReset() : router.go(-1);
          });
        }
      }
    }

    function handleUploadImg(e) {
      const { url, item } = e;
      state.fromItem[getPropIndex(item.prop)].urlList = [url];
      state.fromItem[getPropIndex(item.prop)].value = url;
    }

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      ...toRefs(state),
      submitApi,
      handleUploadImg
    };
  }
};
</script>

<style lang="scss" scoped>
.mainImgBox {
  display: flex;
  align-items: center;
  .mainImg {
    border: 1px solid #ccc;
    width: 100px;
    height: 100px;
    margin-left: 12px;
  }
}
</style>