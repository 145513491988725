<template>
  <div class="addTicket">
    <a-form ref="formRef" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <template v-for="(item,index) in fromItem" :key="index">
        <a-form-item
          v-if="!item.hidden"
          :label="item.label"
          :rules="item.rules === false ? [] : typeof item.rules === 'undefined' ? [{ required: true, message: `请输入${item.label}` }] : item.rules"
          :name="item.prop"
        >
          <!-- input -->
          <a-input v-if="item.type ==='input'" v-model:value="item.value">
            <template #suffix v-if="item.tip">
              <a-tooltip :title="item.tip">
                <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
              </a-tooltip>
            </template>
          </a-input>
          <!-- radio -->
          <a-radio-group v-if="item.type ==='radio'" v-model:value="item.value">
            <a-radio
              v-for="(radio, radioIndex) in item.radioList"
              :value="radio.value"
              :key="radioIndex"
            >{{radio.label}}</a-radio>
          </a-radio-group>
          <!--  -->
          <div v-if="item.type ==='uploadImg'" class="imgList">
            <div class="imgList_imgs">
              <img v-for="(url, urlIndex) in item.urlList" :key="urlIndex" :src="url" alt />
            </div>
            <upLoadImg @success="handleUploadImg($event, item)" />
          </div>
          <!--  -->
          <!--  -->
        </a-form-item>
      </template>
      <!--  -->
      <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">确定</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { toRefs, reactive, computed } from "vue";
import upLoadImg from "@/components/upLoadImg.vue";
export default {
  components: { upLoadImg, InfoCircleOutlined },
  props: {
    fromItem: {
      type: Array,
      default: () => [
        { type: "input", label: "姓名", value: "value" },
        {
          type: "radio",
          label: "销售类型",
          value: "buyType",
          radioList: [
            { label: "按本", value: "0" },
            { label: "按张", value: "1" }
          ]
        },
        {
          type: "uploadImg",
          label: "上传图片",
          value: "mainImg",
          urlList: ["https://img-home.csdnimg.cn/images/20201124032511.png"]
        }
      ]
    }
  },
  setup(props, content) {
    const state = reactive({
      formRef: ""
    });

    const formState = computed(() => {
      const obj = {};
      props.fromItem.forEach(item => {
        obj[item.prop] = item.value;
      });
      console.log(obj);
      return obj;
    });

    function handleUploadImg(url, item) {
      content.emit("handle-upload-img", { url, item });
    }

    function onSubmit() {
      state.formRef
        .validate()
        .then(result => {
          content.emit("submit", result);
          console.log(result);
        })
        .catch(err => {
          console.log(err);
        });
    }

    function onReset() {
      state.formRef.resetFields();
    }

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      ...toRefs(state),
      formState,
      handleUploadImg,
      onSubmit,
      onReset
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .ant-form-item-control {
  text-align: left;
}
.imgList {
  display: flex;
  flex-wrap: wrap;
  .imgList_imgs {
    img {
      width: 100px;
      height: 100px;
      margin-right: 12px;
    }
  }
}
</style>