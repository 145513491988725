<template>
  <div class="fileBox">
    <div class="file">
      <input ref="inputFile" type="file" @change="handleChange" />
      <div class="text">
        <p>+</p>
        <p>点击/拖拽到此处上传</p>
      </div>
    </div>
    <div class="select">
      <a-dropdown :trigger="['click']">
        <a class="ant-dropdown-link" @click.prevent>
          {{ activeCompress }}
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="activeCompress = item" v-for="item in compressList" :key="item">
              <span>{{ item }}</span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import { uploadImg, showMessage } from "@/utils/util";
import { ref } from "vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "选择表格"
    }
  },
  setup(prop, context) {
    const inputFile = ref("");
    const compress = ref(0);
    const fileExtensions = ["png", "jpg", "jpeg", "gif", "webp"];
    const compressList = ["不压缩", 0.8, 0.6, 0.4];
    const activeCompress = ref("不压缩");

    async function handleChange(e) {
      console.log(e.target.files);
      const file = e.target.files[0];
      inputFile.value.value = "";
      const isImg = fileExtensions.includes(
        file.name
          .split(".")
          .pop()
          .toLowerCase()
      );
      if (isImg) {
        const res = await uploadImg(
          file,
          activeCompress.value == "不压缩" ? false : activeCompress.value
        );
        context.emit("success", res);
      } else {
        showMessage("只能选择图片", "warn");
      }
    }

    return {
      handleChange,
      inputFile,
      compress,
      activeCompress,
      compressList
    };
  }
};
</script>

<style lang="scss" scoped>
.fileBox {
  padding: 0 6px;
  font-size: 12px;
  color: #605e5e;
  width: 80px;
  height: 112px;
  position: relative;
  .file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 80px;
    height: 80px;
    border: 1px dashed #ccc; //虚线
    position: relative;
    &:hover {
      border-color: #8795ff;
      color: #8795ff;
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 80px;
      height: 80px;
      opacity: 0;
      cursor: pointer;
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        line-height: 16px;
        padding: 0;
        margin: 0;
      }
    }
  }
  .select {
    height: 20px;
    border: 1px solid #ccc;
    width: 100%;
    position: absolute;
    bottom: 0px;
    line-height: 20px;
  }
}
</style>